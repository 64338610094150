import { useCallback } from 'react';
import { Card } from 'reactstrap';

import { getPetitionsList } from '../../../api/requests/petitions';
import Base from '../../../components/base/Base';
import FetchProvider from '../../../components/contexts/FetchContext';
import { PaginationQuery } from '../../../types/paginationRequestType';
import { PetitionStatus } from '../../../types/petitions';
import ResolvedPetitionTable from './parts/ResolvedPetitionTable';

export default function ResolvedPetitions() {
  const getPetitionList = useCallback(
    (query: PaginationQuery) =>
      getPetitionsList({ ...query, status: PetitionStatus.Resolved }),
    [],
  );

  return (
    <Base>
      <Card className="pt-4 pb-4">
        <FetchProvider
          defaultQuery={{ items_per_page: 10, page: 0 }}
          request={getPetitionList}
          fetchImmediately
        >
          <ResolvedPetitionTable />
        </FetchProvider>
      </Card>
    </Base>
  );
}
