import {
  FileInputControlled,
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

import { useValidations } from '../../../hooks/useValidations';
import { TranslationsKeys } from '../../../locales/translations';
import { PetitionData, ViewPetition } from '../../../types/petitions';
import PetitionFileDownload from './PetitionFileDownload';

export interface PetitionFormProps {
  careerOptions?: SelectOptionType[];
  downloadFile?: ViewPetition['doc'];
  downloadFileResponse?: ViewPetition['docResponse'];
  onlyView?: boolean;
  isResolved?: boolean;
}
export default function PetitionFormDefault({
  careerOptions = [],
  onlyView = false,
  isResolved = false,
  downloadFile,
  downloadFileResponse,
}: PetitionFormProps) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const maxValidation = useCallback(
    (value: number) => {
      return {
        value,
        message: t(`modal.form.validation.max`, { value }),
      };
    },
    [t],
  );

  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const { control } = useFormContext<PetitionData>();

  const text = useMemo(() => {
    const prefix = 'modal.form';
    return {
      studyPlan: t(`${prefix}.studyPlan`),
      phone: t(`${prefix}.phone`),
      email: t(`${prefix}.email`),
      type: t(`${prefix}.type`),
      title: t(`${prefix}.title`),
      reason: {
        label: t(`${prefix}.reason.label`),
        placeholder: t(`${prefix}.reason.placeholder`),
      },
      file: {
        label: t(`${prefix}.file.label`),
        button: t(`${prefix}.file.button`),
      },
      download: {
        labelResponse: t(`${prefix}.download.labelResponse`),
        label: t(`${prefix}.download.label`),
        button: t(`${prefix}.download.button`),
      },
      validation: {
        select: t(`${prefix}.validation.select`),
      },
      resolved: {
        label: t(`${prefix}.resolved.label`),
      },
    };
  }, [t]);

  return (
    <>
      <Col md={12}>
        <Select
          label={text.studyPlan}
          control={control}
          disabled={onlyView}
          options={careerOptions}
          rules={{
            required: text.validation.select,
          }}
          name="studyPlanEnrollment"
          isClearable={false}
        />
      </Col>
      <Col md={6} className="my-3">
        <TextInput
          label={text.phone}
          name="cellPhone"
          disabled={onlyView}
          control={control}
          rules={{
            validate: { validatePhone, require: validateTextNotEmpty },
          }}
        />
      </Col>
      <Col md={6} className="my-3">
        <TextInput
          label={text.email}
          name="email"
          control={control}
          disabled={onlyView}
          rules={{
            validate: { validateEmail, require: validateTextNotEmpty },
          }}
        />
      </Col>
      <Col md={12} className="my-3">
        <TextInput
          label={text.title}
          name="title"
          control={control}
          disabled={onlyView}
          rules={{
            validate: {
              require: validateTextNotEmpty,
            },
            maxLength: maxValidation(50),
          }}
        />
      </Col>
      <Col md={12}>
        <TextAreaInput
          label={text.reason.label}
          name="description"
          placeholder={text.reason.placeholder}
          disabled={onlyView}
          rules={{
            validate: {
              require: validateTextNotEmpty,
            },
            maxLength: maxValidation(500),
          }}
          control={control}
        />
      </Col>

      {isResolved && (
        <Col md={12} className="mt-4">
          <TextAreaInput
            label={text.resolved.label}
            name="resolved"
            disabled={true}
            control={control}
          />
        </Col>
      )}

      <Col md={12} className="my-4">
        {onlyView ? (
          <PetitionFileDownload
            text={text.download.button}
            label={text.download.label}
            file={downloadFile}
          />
        ) : (
          <FileInputControlled
            name="doc"
            label={text.file.label}
            accept="image/png, image/jpeg, application/pdf"
            btnText={text.file.button}
            control={control}
          />
        )}
      </Col>

      {isResolved && downloadFileResponse && (
        <Col md={12} className="my-4">
          <PetitionFileDownload
            text={text.download.button}
            label={text.download.labelResponse}
            file={downloadFileResponse}
          />
        </Col>
      )}
    </>
  );
}
