import { useEffect, useMemo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Col } from 'reactstrap';
import {
  FileInputControlled,
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useValidations } from '../../../../hooks/useValidations';
import { PetitionData, ViewPetition } from '../../../../types/petitions';
import PetitionFileDownload from '../../parts/PetitionFileDownload';
import { TranslationsKeys } from '../../../../locales/translations';
import { SectionType } from '../../../../types/sectionType';

export interface PetitionFormGradeReassesmentProps {
  careerOptions?: SelectOptionType[];
  sectionData?: {
    section: SectionType;
    studentSummaryGradeId: number;
  }[];
  downloadFile?: ViewPetition['doc'];
  downloadFileResponse?: ViewPetition['docResponse'];
  onlyView?: boolean;
  isResolved?: boolean;
}

export default function PetitionFormGradeReassesment({
  careerOptions = [],
  sectionData = [],
  onlyView = false,
  isResolved = false,
  downloadFile,
  downloadFileResponse,
}: PetitionFormGradeReassesmentProps) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const maxValidation = useCallback(
    (value: number) => ({
      value,
      message: t(`modal.form.validation.max`, { value }),
    }),
    [t],
  );
  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const { control, setValue } = useFormContext<PetitionData>();

  const text = useMemo(() => {
    const prefix = 'modal.form';
    return {
      studyPlan: t(`${prefix}.studyPlan`),
      phone: t(`${prefix}.phone`),
      email: t(`${prefix}.email`),
      title: t(`${prefix}.title`),
      reason: {
        label: t(`${prefix}.reason.label`),
        placeholder: t(`${prefix}.reason.placeholder`),
      },
      sections: {
        label: t(`${prefix}.sections.label`),
        placeholder: t(`${prefix}.sections.placeholder`),
      },
      file: {
        label: t(`${prefix}.file.label`),
        button: t(`${prefix}.file.button`),
      },
      download: {
        labelResponse: t(`${prefix}.download.labelResponse`),
        label: t(`${prefix}.download.label`),
        button: t(`${prefix}.download.button`),
      },
      validation: {
        select: t(`${prefix}.validation.select`),
      },
      resolved: {
        label: t(`${prefix}.resolved.label`),
      },
    };
  }, [t]);

  const sectionOptions = useMemo(() => {
    return sectionData.map((data) => ({
      value: data.section.id,
      label: data.section.name,
    }));
  }, [sectionData]);

  const section = useWatch<PetitionData>({
    name: 'section',
    control,
  });

  const title = useWatch<PetitionData>({
    name: 'title',
    control,
  });

  useEffect(() => {
    if (section?.label) {
      setValue('title', `Habilitación: ${section.label}`);
      setValue(
        'studentSummaryGradeId',
        sectionData.find((s) => s.section.id === section.value)
          ?.studentSummaryGradeId,
      );
    }
  }, [section, setValue]);

  return (
    <>
      <Col md={12}>
        <Select
          label={text.studyPlan}
          control={control}
          disabled={onlyView}
          options={careerOptions}
          rules={{
            required: text.validation.select,
          }}
          name="studyPlanEnrollment"
          isClearable={false}
        />
      </Col>
      <Col md={6} className="my-3">
        <TextInput
          label={text.phone}
          name="cellPhone"
          disabled={onlyView}
          control={control}
          rules={{
            validate: { validatePhone, require: validateTextNotEmpty },
          }}
        />
      </Col>
      <Col md={6} className="my-3">
        <TextInput
          label={text.email}
          name="email"
          control={control}
          disabled={onlyView}
          rules={{
            validate: { validateEmail, require: validateTextNotEmpty },
          }}
        />
      </Col>
      <Col md={12} className="my-3">
        <Select
          label={text.sections.label}
          placeholder={text.sections.placeholder}
          name="section"
          control={control}
          options={sectionOptions}
          disabled={onlyView}
          rules={{ required: 'Seleccione una nota a suplir' }}
          isClearable={false}
        />
      </Col>
      <Col md={12}>
        <TextAreaInput
          label={text.reason.label}
          name="description"
          placeholder={text.reason.placeholder}
          disabled={onlyView}
          rules={{
            validate: { require: validateTextNotEmpty },
            maxLength: maxValidation(500),
          }}
          control={control}
        />
      </Col>
      <Col md={12} className="my-4">
        {onlyView ? (
          <PetitionFileDownload
            text={text.download.button}
            label={text.download.label}
            file={downloadFile}
          />
        ) : (
          <FileInputControlled
            name="doc"
            label={text.file.label}
            accept="image/png, image/jpeg, application/pdf"
            btnText={text.file.button}
            control={control}
          />
        )}
      </Col>

      {isResolved && downloadFileResponse && (
        <Col md={12} className="my-4">
          <PetitionFileDownload
            text={text.download.button}
            label={text.download.labelResponse}
            file={downloadFileResponse}
          />
        </Col>
      )}
    </>
  );
}
