import { DisplayInfo, Table, TablePagination } from '@octano/global-ui';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import { useFetch } from '../../../../components/contexts/FetchContext';
import DisplayError from '../../../../components/info/DisplayError';
import { usePagination } from '../../../../hooks/usePagination';
import { TranslationsKeys } from '../../../../locales/translations';
import {
  PaginationQuery,
  PaginationRequestType,
} from '../../../../types/paginationRequestType';
import { PetitionInf } from '../../../../types/petitions';
import useColumnPetitions from '../hooks/useColumnPetitions';
import ViewPetitionModal from '../../parts/ViewPetitionModal';

export default function PetitionTable({}) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const { data, error, query, updateQuery, loading } = useFetch<
    PaginationQuery,
    PaginationRequestType<PetitionInf>,
    { code: string }
  >();
  const {
    isPaginated,
    totalPages,
    itemsPerPage,
    items,
    currentPage,
    onChangePage,
    total,
  } = usePagination<PetitionInf, PaginationQuery>({
    query,
    items: data,
    search: updateQuery,
    itemsPerPage: 10,
    page: 0,
  });
  const prefix = 'table';
  const text = useMemo(
    () => ({
      empty: {
        title: t(`${prefix}.empty.title`),
        body: t(`${prefix}.empty.subtitle`),
      },
      error: {
        title: t(`${prefix}.error.title`),
        body: t(`${prefix}.error.body`),
      },
    }),
    [t],
  );
  const [selectedPetitionId, setPetition] = useState<number | undefined>();

  const toggle = useCallback((petition?: PetitionInf) => {
    if (petition && 'folio' in petition) {
      setPetition(petition.folio);
    } else {
      setPetition(undefined);
    }
  }, []);

  const columns = useColumnPetitions({ toggle });

  if (error) {
    return (
      <DisplayError
        insideCard
        loadingAction={loading}
        title={text.error.title}
        body={text.error.body}
        retryAction={updateQuery}
      />
    );
  }

  return (
    <Card className="mt-4" style={{ minHeight: 333 }}>
      <Table
        columns={columns}
        data={items}
        noResultsText={
          <DisplayInfo
            insideCard
            maxWidth="400px"
            textBody={text.empty.body}
            title={text.empty.title}
          />
        }
        isLoadingResults={loading}
      />
      {isPaginated && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage,
            onChangePage,
            totalItems: total,
            totalPages,
          }}
        />
      )}
      <ViewPetitionModal petitionId={selectedPetitionId} onClose={toggle} />
    </Card>
  );
}
